import React from 'react'
import { graphql } from 'gatsby'
import HomePage from '../templates/home-page'

export const IndexPage = ({ data }) => (
  <HomePage data={{ home: data.home.edges[0].node, projects: data.projects }} />
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    home: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            mt_top
            mt_bottom
            main_paragraph_title
            portrait {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            paragraph_2 {
              title
              text
            }
            projects {
              project
            }
            paragraph_3 {
              text
              email
              links {
                name
                type
                path
              }
            }
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            project_id
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
